.scroll-to-top-button {
    position: fixed;
    bottom: 5px;
    right: 1%;
    width: 50px;
    height: 50px;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
  .scroll-to-top-button:hover{
    background-color: goldenrod;
  }
  
  @media only screen and (max-width: 768px){

    .scroll-to-top-button {
      bottom: 5px;
      height: 35px;
      width: 35px;
    }
  }
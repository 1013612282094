.whatsapp-button {
    position: fixed;
    bottom: 6%;
    left: 1%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: green;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.whatsapp-button:hover{
    background-color: aqua;
}

/* For smaller screens */
@media only screen and (max-width: 600px) {
    .whatsapp-button {
        bottom: 8%;
        left: 0.9%;
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
}

/* For larger screens */
@media only screen and (min-width: 1200px) {
    .whatsapp-button {
        bottom: 60px;
        left: 10px;
        width: 60px;
        height: 60px;
        font-size: 30px;
    }
}
